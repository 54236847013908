import { Backdrop, Box, Button, Card, Container, Grid, Stack, styled, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { NumberInput } from "../../components/NumberInput";
import { useCallback } from 'react';

import * as anchor from '@coral-xyz/anchor';

import { useConnection, useWallet, useAnchorWallet } from '@solana/wallet-adapter-react';

import { LoadingButton } from "@mui/lab";
import { findMetadataPda, findMasterEditionPda } from '@metaplex-foundation/mpl-token-metadata';

import IDL from '../../program/vault.json';
import { AnchorProvider } from '@coral-xyz/anchor';
import { hippoMint } from '../../constants/token';
import { PublicKey, TransactionInstruction } from "@solana/web3.js";
import { createAssociatedTokenAccountInstruction, createTransferInstruction, getAccount, getAssociatedTokenAddress, getAssociatedTokenAddressSync, TOKEN_PROGRAM_ID } from "@solana/spl-token";
import Decimal from "decimal.js";
import { convertTxToURL } from "../../utils/convertTxToURL";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { ASSOCIATED_PROGRAM_ID } from "@coral-xyz/anchor/dist/cjs/utils/token";
import { BN } from "bn.js";
import {
    createSignerFromKeypair,
    signerIdentity,
    publicKey as umiPubkey,
  } from "@metaplex-foundation/umi";
import { endpoint } from "utils/constants";
import { createUmi } from "@metaplex-foundation/umi-bundle-defaults";
const x = '981kdjjl0192flcjs'

export default function Admin() {
    const [p, setP] = useState('')
    const [accessToken, setAccessToken] = useState('')
    const [address, setAddress] = useState('')
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const { publicKey, sendTransaction } = useWallet()
    const { connection } = useConnection()
    const { connected: isSolanaConnected } = useWallet()

    const [userInfo, setUserInfo] = useState()

    const wallet = useAnchorWallet()
    const provider = new AnchorProvider(connection, wallet, {})
    const program = new anchor.Program(IDL, provider)

    const collectDevFee = useCallback(async () => {
        if (!publicKey) return
        setLoading(true)
        try {

            const [vaultInfo] = await PublicKey.findProgramAddress(
                [Buffer.from('vault')],
                program.programId
            )
            const vault = await program.account.vaultInfo.fetch(vaultInfo)

            const devFee = vault.totalFeeCollect.toNumber()
            const senderTokenAccount = await getAssociatedTokenAddress(hippoMint.publicKey, publicKey)

            const tx = new anchor.web3.Transaction()

            try {
                const tokenAccountInfo = await getAccount(connection, senderTokenAccount,undefined, TOKEN_PROGRAM_ID);
            }catch(e){
                tx.add(
                    createAssociatedTokenAccountInstruction(
                      publicKey, senderTokenAccount, publicKey, hippoMint.publicKey, TOKEN_PROGRAM_ID,
                    )
                  )
            }

            tx.add(await program.methods.withdrawDevFee().accounts(
                {
                    stakeMint: hippoMint.publicKey,
                    signer: publicKey,
                    ownerTokenAcount: senderTokenAccount,
                }
            ).instruction()
            )
            console.log("devFee", devFee)
            const quoteResponse = await (
                // eslint-disable-next-line no-multi-str
                await fetch(`https://quote-api.jup.ag/v6/quote?inputMint=GZq2cLK6mS4cP19sa94AhvmwQhzU5zsXYa75ta3xpump&outputMint=So11111111111111111111111111111111111111112&amount=${devFee}&slippageBps=200`
                )
            ).json();

            const instructions = await (
                await fetch('https://quote-api.jup.ag/v6/swap-instructions', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        // quoteResponse from /quote api
                        quoteResponse,
                        userPublicKey: publicKey,
                    })
                })
            ).json();
            console.log("instructions", instructions)
            const {
                tokenLedgerInstruction, // If you are using `useTokenLedger = true`.
                computeBudgetInstructions, // The necessary instructions to setup the compute budget.
                setupInstructions, // Setup missing ATA for the users.
                swapInstruction: swapInstructionPayload, // The actual swap instruction.
                cleanupInstruction, // Unwrap the SOL if `wrapAndUnwrapSol = true`.
                addressLookupTableAddresses, // The lookup table addresses that you can use if you are using versioned transaction.
            } = instructions;


            const deserializeInstruction = (instruction) => {
                console.log("instruction", instruction)
                return new TransactionInstruction({
                    programId: new PublicKey(instruction.programId),
                    keys: instruction.accounts.map((key) => ({
                        pubkey: new PublicKey(key.pubkey),
                        isSigner: key.isSigner,
                        isWritable: key.isWritable,
                    })),
                    data: Buffer.from(instruction.data, "base64"),
                });
            };
            tx.add(...computeBudgetInstructions.map((setup) => { return deserializeInstruction(setup) }), ...setupInstructions.map((setup) => { return deserializeInstruction(setup) }), deserializeInstruction(swapInstructionPayload), deserializeInstruction(cleanupInstruction))

            const {
                context: { slot: minContextSlot },
                value: { blockhash, lastValidBlockHeight },
            } = await connection.getLatestBlockhashAndContext()

            const signature = await sendTransaction(tx, connection)
            console.log('signature', signature)
            //   await connection.confirmTransaction({ blockhash, lastValidBlockHeight, signature })
        } catch (e) {
            console.log("a", e)
        } finally {
            setLoading(false)
        }

    }, [address, connection, program.methods, publicKey, sendTransaction])

    const init = useCallback(async () => {
        if(!publicKey) return
        const seedBuffer = Buffer.from("vault");

        // Find the PDA (Program Derived Address)
        /// this is
        const [pda] = await anchor.web3.PublicKey.findProgramAddressSync(
          [seedBuffer], // Same seed as in your program
          program.programId // The program ID (same as the one used in the on-chain program)
        );
  
        
        console.log("wallet", publicKey)
  
        const connection = provider.connection;
        
        const tx = new anchor.web3.Transaction()

      
       tx.add(await program.methods
          .init(
            new BN(5),
            publicKey,
          )
          .accounts({
            authority: publicKey
          })
          .instruction()
        )
  
              
          const {
            context: { slot: minContextSlot },
            value: { blockhash, lastValidBlockHeight },
        } = await connection.getLatestBlockhashAndContext()

        const signature = await sendTransaction(tx, connection)
        console.log('signature', signature)

  
    },[publicKey])



    const createCollection = useCallback(async () => {
        if(!publicKey) return
        const seedBuffer = Buffer.from("vault");

      // Find the PDA (Program Derived Address)
      /// this is
      const [pda] = await anchor.web3.PublicKey.findProgramAddressSync(
        [seedBuffer], // Same seed as in your program
        program.programId // The program ID (same as the one used in the on-chain program)
      );

      
      console.log("wallet", publicKey)

      const connection = provider.connection;


      const tx = new anchor.web3.Transaction()

        const [collectionPDA] = anchor.web3.PublicKey.findProgramAddressSync(
          [Buffer.from("Collection")],
          program.programId
        );
      
        const umi = createUmi(endpoint, "confirmed");

        const collectionMint = umiPubkey(collectionPDA)

        const metadataPDA = findMetadataPda(umi, { mint: collectionMint });
  
        const masterEditionPDA = findMasterEditionPda(umi, { mint: collectionMint });
    
    
        const modifyComputeUnits =
          anchor.web3.ComputeBudgetProgram.setComputeUnitLimit({
            units: 300_000,
          });

        tx.add(modifyComputeUnits)

        tx.add(await program.methods
        .createCollectionNft(
          "https://bafybeigvasvth3enh4g2ahvgic57cz2ju2vngp57bhvqidjidnycnqtxru.ipfs.w3s.link/BLACK-NINJA.json",
          "HERO",
          "HERO"
        )
        .accounts({
          authority: publicKey,
          metadataAccount: metadataPDA[0],
          masterEdition: masterEditionPDA[0],
        })
        .instruction()
        );

      
        const {
            context: { slot: minContextSlot },
            value: { blockhash, lastValidBlockHeight },
        } = await connection.getLatestBlockhashAndContext()

        const signature = await sendTransaction(tx, connection)
        console.log('signature', signature)

  
    },[publicKey])




    const withdrawLP = useCallback(async () => {
        if (!publicKey) return
        setLoading(true)
        try {
            const [vaultInfo] = await PublicKey.findProgramAddress(
                [Buffer.from('vault')],
                program.programId
            )
            const [vauTokenAddress] = await PublicKey.findProgramAddressSync(
                [vaultInfo.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), hippoMint.publicKey.toBuffer()],
                ASSOCIATED_PROGRAM_ID,
            );

            const vault = await getAccount(provider.connection, vauTokenAddress, "confirmed")


            const senderTokenAccount = await getAssociatedTokenAddress(hippoMint.publicKey, publicKey)

            const tx = new anchor.web3.Transaction()

            try {
                const tokenAccountInfo = await getAccount(connection, senderTokenAccount,undefined, TOKEN_PROGRAM_ID);
            }catch(e){
                tx.add(
                    createAssociatedTokenAccountInstruction(
                      publicKey, senderTokenAccount, publicKey, hippoMint.publicKey, TOKEN_PROGRAM_ID,
                    )
                  )
            }
            tx.add(await program.methods
                .withdrawLp()
                .accounts({
                  stakeMint: hippoMint.address,
                  signer: publicKey,
                  signerStakeAccount: senderTokenAccount,
                }).instruction()
            )
            // const quoteResponse = await (
            //     // eslint-disable-next-line no-multi-str
            //     await fetch(`https://quote-api.jup.ag/v6/quote?inputMint=GZq2cLK6mS4cP19sa94AhvmwQhzU5zsXYa75ta3xpump&outputMint=So11111111111111111111111111111111111111112&amount=${vault.amount}&slippageBps=200`
            //     )
            // ).json();

            // const instructions = await (
            //     await fetch('https://quote-api.jup.ag/v6/swap-instructions', {
            //         method: 'POST',
            //         headers: {
            //             'Content-Type': 'application/json'
            //         },
            //         body: JSON.stringify({
            //             // quoteResponse from /quote api
            //             quoteResponse,
            //             userPublicKey: publicKey,
            //         })
            //     })
            // ).json();
            // console.log("instructions", instructions)
            // const {
            //     tokenLedgerInstruction, // If you are using `useTokenLedger = true`.
            //     computeBudgetInstructions, // The necessary instructions to setup the compute budget.
            //     setupInstructions, // Setup missing ATA for the users.
            //     swapInstruction: swapInstructionPayload, // The actual swap instruction.
            //     cleanupInstruction, // Unwrap the SOL if `wrapAndUnwrapSol = true`.
            //     addressLookupTableAddresses, // The lookup table addresses that you can use if you are using versioned transaction.
            // } = instructions;


            // const deserializeInstruction = (instruction) => {
            //     console.log("instruction", instruction)
            //     return new TransactionInstruction({
            //         programId: new PublicKey(instruction.programId),
            //         keys: instruction.accounts.map((key) => ({
            //             pubkey: new PublicKey(key.pubkey),
            //             isSigner: key.isSigner,
            //             isWritable: key.isWritable,
            //         })),
            //         data: Buffer.from(instruction.data, "base64"),
            //     });
            // };
            // tx.add(...computeBudgetInstructions.map((setup) => { return deserializeInstruction(setup) }), ...setupInstructions.map((setup) => { return deserializeInstruction(setup) }), deserializeInstruction(swapInstructionPayload), deserializeInstruction(cleanupInstruction))

         

            const signature = await sendTransaction(tx, connection)
            console.log('signature', signature)
            //   await connection.confirmTransaction({ blockhash, lastValidBlockHeight, signature })
        } catch (e) {
            console.log("a", e)
        } finally {
            setLoading(false)
        }

    }, [address, connection, program.methods, publicKey, sendTransaction])


    return <PageContainer >
        <Grid container minHeight={'100vh'}>
            <Grid item xs={2}>
                <SideBar></SideBar>
            </Grid>
            <Grid item xs={10} sx={{ position: "relative" }}>
                {
                    accessToken === x ? <Container style={{ padding: '1rem 0px' }}>
                        <WalletMultiButton>
                            {isSolanaConnected ? (
                                ''
                            ) : (
                                <span className='flex items-center gap-2'>
                                    Connect Wallet
                                </span>
                            )}
                        </WalletMultiButton>
                        <Stack sx={{ minHeight: '100vh' }} justifyContent={'center'} textAlign={'center'}>
                            <Box>
                                <Grid container rowSpacing={10} columnSpacing={3} justifyContent={'center'}>
                                    <Grid item xs={6}>
                                        <Card sx={{ background: "#fff", padding: "2rem" }}>
                                            <MintHippo />
                                        </Card>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Card sx={{ background: "#fff", padding: "2rem" }}>
                                            <SetNewOwwner />
                                        </Card>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Card sx={{ background: "#fff", padding: "2rem" }}>
                                            <Deposit />
                                        </Card>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <LoadingButton loading={loading} onClick={(e) => { collectDevFee() }} sx={{ fontSize: '1.2rem' }} fullWidth>Withdraw dev fee</LoadingButton>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <LoadingButton loading={loading} onClick={(e) => { withdrawLP() }} sx={{ fontSize: '1.2rem' }} fullWidth>Withdraw LP</LoadingButton>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <LoadingButton loading={loading} onClick={(e) => { init() }} sx={{ fontSize: '1.2rem' }} fullWidth>Init</LoadingButton>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <LoadingButton loading={loading} onClick={(e) => { createCollection() }} sx={{ fontSize: '1.2rem' }} fullWidth>Create Collection</LoadingButton>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Stack>
                    </Container> : <Backdrop open={true} in={true} sx={{ position: 'absolute', background: 'none' }}>
                        <Stack spacing={2}>
                            <TextField
                                value={p}
                                onChange={e => setP(e.target.value)}
                                placeholder="Password"
                                sx={{
                                    border: 'none',
                                    '& .MuiInputBase-input': {
                                        color: '#000',
                                    }
                                }} />
                            <Button onClick={() => setAccessToken(p)} fullWidth sx={{ fontSize: '1.2rem' }}>Login</Button>
                        </Stack>
                    </Backdrop>
                }
            </Grid>
        </Grid>
    </PageContainer>
}
const MintHippo = () => {
    const [address, setAddress] = useState('')
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const { publicKey, sendTransaction } = useWallet()
    const { connection } = useConnection()

    const [userInfo, setUserInfo] = useState()

    const wallet = useAnchorWallet()
    const provider = new AnchorProvider(connection, wallet, {})
    const program = new anchor.Program(IDL, provider)

    const setHippoMint = useCallback(async () => {
        if (!publicKey) return
        setLoading(true)
        try {
            // const seedBuffer = Buffer.from('vault');

            // // Find the PDA (Program Derived Address)
            // /// this is 
            // const [pda] = await anchor.web3.PublicKey.findProgramAddressSync(
            //     [seedBuffer], // Same seed as in your program
            //     program.programId     // The program ID (same as the one used in the on-chain program)
            // );


            // await program.methods.initialize(new BN(2920), new BN(5), new PublicKey('DFFnbg4bi76LkAFRigRpaiS7JR23mDQZrZr6pr9uZpJx')).accounts(
            //     {
            //         initializer: wallet.payer.publicKey,
            //     }
            // ).rpc({ skipPreflight: false })

            const tx = new anchor.web3.Transaction()
            tx.add(await program.methods.updateStakeMint().accounts(
                {
                    stakeMint: new PublicKey(address),
                }
            ).instruction()
            )
            const {
                context: { slot: minContextSlot },
                value: { blockhash, lastValidBlockHeight },
            } = await connection.getLatestBlockhashAndContext()

            const signature = await sendTransaction(tx, connection)
            console.log('signature', signature)
            //   await connection.confirmTransaction({ blockhash, lastValidBlockHeight, signature })
        } catch (e) {
            console.log("a", e)

        } finally {
            setLoading(false)
        }

    }, [address, connection, program.methods, publicKey, sendTransaction])

    return <Stack spacing={2}>
        <Typography variant="h5" color={'#000'}>Mint hippo</Typography>
        <TextField
            value={address}
            onChange={e => setAddress(e.target.value)}
            placeholder="Address"
            sx={{
                border: 'none',
                '& .MuiInputBase-input': {
                    color: '#000',
                }
            }} />
        <LoadingButton loading={loading} onClick={(e) => { setHippoMint() }} fullWidth sx={{ fontSize: '1.2rem' }}>Set</LoadingButton>
    </Stack>
}


const SetNewOwwner = () => {
    const [address, setAddress] = useState('')
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const { publicKey, sendTransaction } = useWallet()
    const { connection } = useConnection()

    const [userInfo, setUserInfo] = useState()

    const wallet = useAnchorWallet()
    const provider = new AnchorProvider(connection, wallet, {})
    const program = new anchor.Program(IDL, provider)

    const transferOwner = useCallback(async () => {
        if (!publicKey) return
        setLoading(true)
        try {
            const seedBuffer = Buffer.from('vault');

            // Find the PDA (Program Derived Address)
            /// this is 
            const [pda] = await anchor.web3.PublicKey.findProgramAddressSync(
                [seedBuffer], // Same seed as in your program
                program.programId     // The program ID (same as the one used in the on-chain program)
            );


            // await program.methods.initialize(new BN(2920), new BN(5), new PublicKey('DFFnbg4bi76LkAFRigRpaiS7JR23mDQZrZr6pr9uZpJx')).accounts(
            //     {
            //         initializer: wallet.payer.publicKey,
            //     }
            // ).rpc({ skipPreflight: false })

            const tx = new anchor.web3.Transaction()
            tx.add(await program.methods.transferOwner(new PublicKey(address)).accounts({
                signer: publicKey
            }).instruction()
            )
            const {
                context: { slot: minContextSlot },
                value: { blockhash, lastValidBlockHeight },
            } = await connection.getLatestBlockhashAndContext()

            const signature = await sendTransaction(tx, connection)
            console.log('signature', signature)
            //   await connection.confirmTransaction({ blockhash, lastValidBlockHeight, signature })
        } catch (e) {
            console.log("a", e)

        } finally {
            setLoading(false)
        }

    }, [address, connection, program.methods, publicKey, sendTransaction])

    return <Stack spacing={2}>
        <Typography variant="h5" color={'#000'}>Set address claim</Typography>
        <TextField
            value={address}
            onChange={e => setAddress(e.target.value)}
            placeholder="Address"
            sx={{
                border: 'none',
                '& .MuiInputBase-input': {
                    color: '#000',
                }
            }} />
        <LoadingButton loading={loading} onClick={(e) => { transferOwner() }} fullWidth sx={{ fontSize: '1.2rem' }}>Transfer</LoadingButton>
    </Stack>
}
const Deposit = () => {
    const [amount, setAmount] = useState('string')
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const { publicKey, sendTransaction } = useWallet()
    const { connection } = useConnection()

    const [userInfo, setUserInfo] = useState()

    const wallet = useAnchorWallet()
    const provider = new AnchorProvider(connection, wallet, {})
    const program = new anchor.Program(IDL, provider)

    const deposit = useCallback(async () => {
        if (!publicKey) return
        setLoading(true)
        try {
            const senderTokenAccount = await getAssociatedTokenAddress(hippoMint.publicKey, publicKey, true)

            const [vaultInfo] = await PublicKey.findProgramAddress(
                [Buffer.from('vault')],
                program.programId
            )
            const [vauTokenAddress] = await PublicKey.findProgramAddressSync(
                [vaultInfo.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), hippoMint.publicKey.toBuffer()],
                ASSOCIATED_PROGRAM_ID,
            );

            console.log("vauTokenAddress", vauTokenAddress)
            const amountTx = new Decimal(amount ?? 0).mul(Decimal.pow(10, 6)).toNumber(); // amount of tokens (adjust decimals as needed)

            const tx = new anchor.web3.Transaction()

            tx.add(createTransferInstruction(
                senderTokenAccount,     // sender's token account
                new PublicKey(vauTokenAddress),  // recipient's token account
                publicKey, // authority (wallet signing the transaction)
                amountTx,                 // amount of tokens to transfer
                [],                     // multisig (if any)
                TOKEN_PROGRAM_ID       // SPL Token program ID
            ))

            const {
                context: { slot: minContextSlot },
                value: { blockhash, lastValidBlockHeight },
            } = await connection.getLatestBlockhashAndContext()

            const signature = await sendTransaction(tx, connection)
            //   await connection.confirmTransaction({ blockhash, lastValidBlockHeight, signature })
            const url = convertTxToURL(signature)
            window.open(url, '_blank')
        } catch (e) {
            console.log("a", e)
        } finally {
            setLoading(false)
        }

    }, [amount, connection, program.programId, publicKey, sendTransaction])

    return <Stack spacing={2}>
        <Typography variant="h5" color={'#000'}>Deposit</Typography>
        <NumberInput
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            placeholder="Amount"
            sx={{
                border: 'none',
                '& .MuiInputBase-input': {
                    color: '#000',
                },
            }} />
        <LoadingButton loading={loading} onClick={(e) => { deposit() }} fullWidth sx={{ fontSize: '1.2rem' }}>Deposit</LoadingButton>
    </Stack>
}
const SideBar = styled(Card)(() => ({
    height: '100%',
    background: '#fff',
    borderRadius: '0px'
}));
const PageContainer = styled(Stack)(() => ({
    minHeight: '100vh',
    background: '#F8FAFD',
    backgroundSize: "cover",
    position: 'relative',
    color: '#000'
}));