import { TOKEN_2022_PROGRAM_ID, TOKEN_PROGRAM_ID } from '@solana/spl-token'
import { PublicKey } from '@solana/web3.js'

export interface Token {
  address: string
  decimals: number
  programId: string
  publicKey: PublicKey
  symbol: string
}

export interface TokenBalance {
  token: Token
  balance: number
}


export const hippoMint: Token = {
  symbol: 'HERO',
  address: 'GZq2cLK6mS4cP19sa94AhvmwQhzU5zsXYa75ta3xpump',
  decimals: 6,
  programId: TOKEN_PROGRAM_ID.toBase58(),
  publicKey: new PublicKey('GZq2cLK6mS4cP19sa94AhvmwQhzU5zsXYa75ta3xpump'),
}
